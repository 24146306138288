<template>
  <div class="h-100 d-flex flex-row position-relative">
        <div id="sidebar-container" class="h-75 position-fixed d-flex gap-2" style="z-index: 10;">
            <div id="sidebar" class="h-100 bg-dark py-5 d-lg-flex flex-column justify-content-between" :class="{'': isOpen, 'd-none':!isOpen}" style="min-height: 100vh;">
                <div class="mb-5">
                <a href="dashboard.php"><img src="../assets/imgs/logo.png" alt="Tatsing-group" class="w-100 mb-5 px-3" style="object-fit: contain;"></a>
                    <div class="d-flex flex-column justify-content-between h-75 gap-3 gap-lg-0">
                        <div class="position-relative">
                            <router-link to="/dashboard" custom v-slot="{ navigate, isActive }">
                                <span class="position-absolute h-100 border-start-secondary border-3 rounded p-0 start-0" :class="{'': isActive, 'd-none': !isActive}"></span>
                                <div class="d-flex gap-4 align-items-center px-3">
                                    <img src="../assets/imgs/icons/house.png" alt="home" width="25" height="25" :class="{'': isActive, 'opacity-50': !isActive}">
                                    <button @click="navigate" class="bg-transparent border-0 p-0 shadow-none">
                                        <span class="fw-bold" :class="{'text-secondary-theme': isActive, 'text-muted text-hover-secondary-theme': !isActive}">
                                            Home
                                        </span>
                                    </button>
                                </div>
                            </router-link>
                        </div>

                        <div class="position-relative" v-if="$store.getters['get_perm']('viewEvents')">
                            <router-link to="/events" custom v-slot="{ navigate, isActive }">
                                <span class="position-absolute h-100 border-start-secondary border-3 rounded p-0 start-0" :class="{'': isActive, 'd-none': !isActive}"></span>
                                <div class="d-flex gap-4 align-items-center px-3">
                                    <img src="../assets/imgs/icons/folder.png" alt="events" width="25" height="25" :class="{'': isActive, 'opacity-50': !isActive}">
                                    <button @click="navigate" class="bg-transparent border-0 p-0 shadow-none">
                                        <span class="fw-bold" :class="{'text-secondary-theme': isActive, 'text-muted text-hover-secondary-theme': !isActive}">
                                            Events
                                        </span>
                                    </button>
                                </div>
                            </router-link>
                        </div>
                        <div class="position-relative" v-if="$store.getters['get_perm']('storeEvent') || $store.getters['get_perm']('updateEvent')">
                            <router-link to="/create-event" custom v-slot="{ navigate, isActive }">
                                <span class="position-absolute h-100 border-start-secondary border-3 rounded p-0 start-0" :class="{'': isActive, 'd-none': !isActive}"></span>
                                <div class="d-flex gap-4 align-items-center px-3">
                                    <img src="../assets/imgs/icons/folder.png" alt="events" width="25" height="25" :class="{'': isActive, 'opacity-50': !isActive}">
                                    <button @click="navigate" class="bg-transparent border-0 p-0 shadow-none">
                                        <span class="fw-bold" :class="{'text-secondary-theme': isActive, 'text-muted text-hover-secondary-theme': !isActive}">
                                            Manage Event
                                        </span>
                                    </button>
                                </div>
                            </router-link>
                        </div>

                        <div class="position-relative" v-if="$store.getters['get_perm']('storeLogistic') || ($store.getters['get_perm']('updateLogistic') && $store.getters['get_perm']('showLogistic'))">
                            <router-link to="/update-status" custom v-slot="{ navigate, isActive }">
                                <span class="position-absolute h-100 border-start-secondary border-3 rounded p-0 start-0" :class="{'': isActive, 'd-none': !isActive}"></span>
                                <div class="d-flex gap-4 align-items-center px-3">
                                    <img src="../assets/imgs/icons/reload.png" alt="update" width="25" height="25" :class="{'': isActive, 'opacity-50': !isActive}">
                                    <button @click="navigate" class="bg-transparent border-0 p-0 shadow-none">
                                        <span class="fw-bold" :class="{'text-secondary-theme': isActive, 'text-muted text-hover-secondary-theme': !isActive}">
                                            Update Status
                                        </span>
                                    </button>
                                </div>
                            </router-link>
                        </div>

                        <div class="position-relative" v-if="$store.getters['get_perm']('viewUsers')">
                            <router-link to="/user-management" custom v-slot="{ navigate, isActive }">
                                <span class="position-absolute h-100 border-start-secondary border-3 rounded p-0 start-0" :class="{'': isActive, 'd-none': !isActive}"></span>
                                <div class="d-flex gap-4 align-items-center px-3">
                                    <img src="../assets/imgs/icons/accounts.png" alt="account" width="27" height="25" :class="{'': isActive, 'opacity-50': !isActive}">
                                    <button @click="navigate" class="bg-transparent border-0 p-0 shadow-none">
                                        <span class="fw-bold" :class="{'text-secondary-theme': isActive, 'text-muted text-hover-secondary-theme': !isActive}">
                                            Manage Users
                                        </span>
                                    </button>
                                </div>
                            </router-link>
                        </div>

                        <div class="position-relative" v-if="$store.getters['get_perm']('spartialUser')">
                            <router-link to="/create-account" custom v-slot="{ navigate, isActive }">
                                <span class="position-absolute h-100 border-start-secondary border-3 rounded p-0 start-0" :class="{'': isActive, 'd-none': !isActive}"></span>
                                <div class="d-flex gap-4 align-items-center px-3">
                                    <img src="../assets/imgs/icons/accounts.png" alt="account" width="27" height="25" :class="{'': isActive, 'opacity-50': !isActive}">
                                    <button @click="navigate" class="bg-transparent border-0 p-0 shadow-none">
                                        <span class="fw-bold" :class="{'text-secondary-theme': isActive, 'text-muted text-hover-secondary-theme': !isActive}">
                                            Create Staff
                                        </span>
                                    </button>
                                </div>
                            </router-link>
                        </div>
                        
                        <div class="position-relative" v-if="$store.getters['get_perm']('updateUser') || $store.getters['get_perm']('uselfUser')">
                            <router-link to="/profile" custom v-slot="{ navigate, isActive }">
                                <span class="position-absolute h-100 border-start-secondary border-3 rounded p-0 start-0" :class="{'': isActive, 'd-none': !isActive}"></span>
                                <div class="d-flex gap-4 align-items-center px-3">
                                    <img src="../assets/imgs/icons/avatar.png" alt="setting" width="25" height="25" :class="{'': isActive, 'opacity-50': !isActive}">
                                    <button @click="navigate" class="bg-transparent border-0 p-0 shadow-none">
                                        <span class="fw-bold" :class="{'text-secondary-theme': isActive, 'text-muted text-hover-secondary-theme': !isActive}">
                                            Profile
                                        </span>
                                    </button>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div id="account" class="d-flex align-items-center gap-3 px-3 position-relative">
                    <img v-if="$store.state.user.sidebar_photo" :src="$store.state.user.sidebar_photo" id="profile-pic" width="40" height="40" class="rounded-circle">
                    <span v-if="!$store.state.user.sidebar_photo" class="rounded-circle shadow-sm bg-muted text-center fw-bold text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                        </svg>
                    </span>
                    <button class="bg-transparent border-0" @click="account_option_open = !account_option_open">
                        <div id="name" class="text-white text-hover-secondary-theme text-start overflow-hidden" style="width: 100px; white-space: nowrap; text-overflow: ellipsis;">{{ $store.getters.GET_USER_NAME }}</div>
                    </button>
                    
                    <transition name="fade" mode="out-in" appear>
                    <div v-show="account_option_open" class="popover fade show bs-popover-end" style="inset: 0px auto auto 0px; margin: 0px; transform: translate3d(200px, -25px, 0px);">
                        <div class="popover-arrow" style="position: absolute; top: 0px; transform: translate3d(0px, 37px, 0px);"></div>
                            <h3 class="popover-header">Account</h3><div class="popover-body"><button class="text-theme text-center border-0 bg-transparent text-hover-secondary-theme fw-bold" @click="logout">Logout</button>
                        </div>
                    </div>
                    </transition>
                </div>
            </div>


            <div class="d-block d-lg-none mt-5">
                <i id="mobile-nav" class="bi text-white bg-theme rounded shadow p-1" :class="{'navbar-mobile bi-x': isOpen, 'bi-list mobile-nav-toggle':!isOpen}" @click="isOpen = !isOpen"></i>
            </div>
        </div>

        <div id="home" class="container p-4 d-flex gap-3 flex-column">
            <router-view/>
        </div>
        
    </div>
</template>

<style scoped src='../assets/css/inner.css'></style>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import { createNamespacedHelpers } from "vuex";
createNamespacedHelpers('user')

export default {
  name: 'Sidebar',
  data() {
      return {
        isOpen: false,
        popoverTrigger: null,
        account_option_open: false,
        user_data: {},
        decoded_photo: ''
      }
  },
  async beforeMount() {
    await this.$store.dispatch("user/getProfile").then(() => {
        Object.assign(this.user_data, this.$store.getters['user/GET_DATA']);
    });
    if(this.user_data.profile_photo) await this.$store.dispatch('user/getPhoto', { id: this.user_data.id, photo_name: this.user_data.profile_photo})
  },
  methods : {
      logout() {
          this.$store.dispatch('logoutUser')
      }
  }
}
</script>