import axios from 'axios'

axios.defaults.withCredentials = true;

export default ({
  namespaced: true,
  state: {
    errors: {
      error_msg: '',
      errors: {}
    },
    user_data: {
      full_name: '',
      username: '',
      email: '',
      password: '',
      password_confirmation: '',
      department: '1',
      profile_photo: '',
      abilities: [],
      role: '',
      job_title: ''
    },
    profile_photo: '',
    users_data: {},
    totalPages: 1,
    currentPage: 1,
    lastPage: 1,
    to: 1,
    from: 1,
    total_entries: 1,
    message: '',
    isLoading: false,
    sidebar_photo: '',
  },
  getters: {
    GET_DATA: function(state) {
      return state.user_data
    }
  },
  mutations: {
    reset_errors(state) {
      return state.errors = {
        error_msg: '',
        errors: {}
      }
    },
    reset_message(state) {
      return state.message = ''
    },
    reset_user_data(state) {
      state.user_data = {
        full_name: '',
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
        department: '1',
        profile_photo: '',
        abilities: [],
        role: '',
        job_title: ''
      }
    },
    isLoading(state) {
      return state.isLoading = !state.isLoading
    },
    change_photo(state, payload) {
      Object.assign(state.user_data, {profile_photo: payload})
      state.profile_photo = payload
    },
    change_sidebar_photo(state, payload) {
      state.sidebar_photo = payload
    }
  },
  actions: {
      async createUser({ state, commit }, user_data) {
        state.isLoading = true
        await axios.post('/api/v1/user', user_data).then(response => {
          state.isLoading = false
          state.message = response.data
          commit('reset_errors')
        }).catch(error => {
          state.isLoading = false
          if(error.response.status === 422) {
            state.message = error.response.data.message;
            if(error.response.data.errors)
            {
              state.errors.errors = error.response.data.errors
              if(error.response.data.errors.password) {
                const password_error = error.response.data.errors.password.filter(function(value){ 
                  return !value.toLowerCase().includes('password confirmation');
                })
                const password_confirmation_error = error.response.data.errors.password.find((error) =>
                error.toLowerCase().includes('password confirmation'))

                Object.assign(state.errors.errors, {'password': password_error, 'password_confirmation': password_confirmation_error})
              }
            }
          }
          else {
            state.errors.error_msg = error.response.statusText;
          }
        });
      },
      
      async getUsers({ state }, table_options) {
        state.isLoading = true
        await axios.get('/api/v1/user', {
          params: {
            search: table_options.search,
            sort_by: table_options.sort_by,
            sort_dir: table_options.sort_dir,
            perPage: table_options.perPage,
            page: table_options.currentPage
          }
        }).then(response => {
          state.isLoading = false
          state.totalPages = response.data.meta.last_page
          state.currentPage = response.data.meta.current_page
          state.lastPage = response.data.meta.last_page
          state.to = response.data.meta.to
          state.from = response.data.meta.from
          state.total = response.data.meta.total
          state.users_data = response.data
        }).catch(error => {
          state.isLoading = false
          state.errors.error_msg = error.response.statusText;
        });
      },

      async getUser({ state }, id) {
        state.isLoading = true
        await axios.get('/api/v1/user/'+id).then(response => {
          state.isLoading = false
          state.user_data = response.data
        }).catch(error => {
          state.isLoading = false
          state.errors.error_msg = error.response.statusText;
        });
      },

      async getProfile({ state }) {
        state.isLoading = true
        await axios.get('/api/v1/user-info').then(response => {
          state.isLoading = false
          localStorage.setItem("user_name", response.data.full_name);
          state.user_data = response.data
        }).catch(error => {
          state.isLoading = false
          state.errors.error_msg = error.response.statusText;
        });
      },
      async updateUser({ state, commit }, data) {
        state.isLoading = true
        await axios.post('/api/v1/user/'+data.id, data).then(() => {
          commit('reset_errors')
          state.message = 'Update Successful'
          state.isLoading = false
        }).catch(error => {
          if(error.response.status === 422) {
            state.message = error.response.data.message;
            if(error.response.data.errors)
            {
              state.errors.errors = error.response.data.errors
            }
          }
          else {
            state.message = error.response.statusText;
          }
          state.isLoading = false
        });
      },
      async deleteUser({ state, commit }, id) {
        await axios.post('/api/v1/user/'+id, {
            _method: 'DELETE'
        }).then(() => {
          commit('reset_errors')
          state.message = 'Delete Successful'
        }).catch(error => {
          state.message = error.response.statusText;
        });
      },
      async upload({ state, commit }, file_data) {
        state.isLoading = true
        await axios.post('/api/v1/upload', file_data).then(response => {
        commit('reset_errors')
        commit('change_photo', response.data)
        state.isLoading = false
        }).catch(error => {
          if(error.response.status === 422) {
            state.message = error.response.data.message;
            if(error.response.data.errors)
            {
              state.errors.errors = error.response.data.errors
            }
          }
          else {
            state.message = error.response.statusText;
          }
        });
        state.isLoading = false
      },
      async getPhoto({commit, state}, data) {
        if(!data.id || !data.photo_name) return
        state.isLoading = true
        await axios.get('api/v1/storage/profile-photos/'+data.id+'/'+data.photo_name, {
          responseType: 'arraybuffer'
        }).then(response => {
          const b64Response = Buffer.from(response.data, 'binary').toString('base64')
          const file_data = 'data:image/png;base64,'+b64Response;
          commit('change_photo', file_data)
          state.sidebar_photo = file_data
          state.isLoading = false
        }).catch(() => {
          // state.message = error.response.statusText
          state.isLoading = false
        })
      }
  },
  modules: {
  }
})
