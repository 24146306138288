export default ({
  namespaced: true,
  state: {
    search: '',
    sort_by: 'created_at',
    sort_dir: 'desc',
    perPage: 10
  },
  getters: {
    GET_SORTBY: function(state) {
      return state.sortByField;
    }
  },
  mutations: {
  },
  actions: {
    sortBy( {state}, $field) {
      state.sortDirection = state.sortByField === $field
          ? this.reverseSort()
          : 'desc';

      state.sortByField = $field;
    },
    reverseSort({state}) {
      state.sortDirection === 'desc'
          ? 'asc'
          : 'desc';
    },
    changePerPage({state}, value) {
      state.perPage = value
    }
  },
  modules: {
  }
})