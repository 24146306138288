import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index.js'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { requiresAuth: false, title: 'Login' }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/tatsing-group/authenticated/Dashboard.vue'),
    meta: { requiresAuth: true, title: 'Dashboard' }
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    component: () => import('../views/tatsing-group/authenticated/UserManagement.vue'),
    meta: { requiresAuth: true, title: 'User Management' }
  },
  {
    path: '/create-account',
    name: 'CreateAccount',
    component: () => import('../views/tatsing-group/authenticated/CreateAccount.vue'),
    meta: { requiresAuth: true, title: 'Create Account' }
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('../views/tatsing-group/authenticated/Event.vue'),
    meta: { requiresAuth: true, title: 'Events' }
  },
  {
    path: '/create-event',
    name: 'CreateEvents',
    component: () => import('../views/tatsing-group/authenticated/CreateEvent.vue'),
    meta: { requiresAuth: true, title: 'Create Event' }
  },
  {
    path: '/update-status',
    name: 'UpdateStatus',
    component: () => import('../views/tatsing-group/authenticated/UpdateStatus.vue'),
    meta: { requiresAuth: true, title: 'Update Status' }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/tatsing-group/authenticated/Profile.vue'),
    meta: { requiresAuth: true, title: 'User Profile' }
  },
  {
    path: "/:catchAll(.*)",
    component: () => import('../views/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta && to.meta.title ? 'TSG ' + to.meta.title : 'TSG MANAGEMENT';

  if (to.meta.requiresAuth && !store.getters.GET_LOGIN_STATUS) {
    next({
      path: '/',
      query: { redirect: to.fullPath },
    })
  }
  else if (!to.meta.requiresAuth && store.getters.GET_LOGIN_STATUS) {
    next({
      path: '/dashboard',
    });
    // if you want to stop/return back to the last page
    // next(false)
    // router.go(-1) ? router.go(-1) : router.push('/dashboard');
    // return
  }
  else{
    store.commit('isLoading')
    store.dispatch('reauth')
    next();
  }
})

router.afterEach(() => {
  store.commit('isFinishedLoading')
})

export default router