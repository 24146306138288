
<template>
<div id="preloader"></div>
</template>

<style>
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
}

#preloader:before {
  content: "";
  position: absolute;
  top: calc(50% - 0px);
  left: calc(50% - 30px);
  border: 6px solid rgb(0, 0, 0, 0.2);
  border-top-color: #6E111D;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
export default {
  name: 'preloader',
  data() {
      return {
          load: true
      }
  },
  beforeMount() {
      this.load = true;
  },
  mounted() {
      this.load = false;
  }
}
</script>