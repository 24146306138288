import axios from 'axios'
import addMinutes from 'date-fns/addMinutes'
export default ({
  namespaced: true,
  state: {
    errors: {
      errors: {}
    },
    isLoading: false,
    message: '',
    notif_message: '',
    logistic_data: {},
    logistic_notif_data: {},
    single_logistic_data: {
      srn: '',
      items: '',
      doc_desc: '',
      doc_date: '',
      doc_time: '',
      etd_desc: '',
      etd_date: '',
      etd_time: '',
      etb_desc: '',
      etb_date: '',
      etb_time: '',
      eta_desc: '',
      eta_date: '',
      eta_time: '',
      customs_desc: '',
      customs_date: '',
      customs_time: '',
      endorsement_desc: '',
      endorsement_date: '',
      endorsement_time: '',
      assessment_desc: '',
      assessment_date: '',
      assessment_time: '',
      paidDuties_desc: '',
      paidDuties_date: '',
      paidDuties_time: '',
      arrastre_desc: '',
      arrastre_date: '',
      arrastre_time: '',
      tabs_desc: '',
      tabs_date: '',
      tabs_time: '',
      gatepass_desc: '',
      gatepass_date: '',
      gatepass_time: '',
      delivery_desc: '',
      delivery_date: '',
      delivery_time: '',
      container_return_desc: 0,
      container_return_date: '',
      container_return_time: '',
      container_return_readableTime: '',
      last_edited: -1,
      remind_at: 5
    },
    static_table_data: [
      'Documents Received',
      'ETD of the Vessel',
      'ETA of the Vessel',
      'ETB of the Vessel',
      'For Shipping Line Endorsement',
      'For Customs Processing',
      'For Final Assessment',
      'Paid Duties',
      'For Arrastde/Wharfage Payment',
      'TABS Booking',
      'For Gatepass',
      'For Delivery',
      'Container Return'
    ],
    totalPages: 1,
    currentPage: 1,
    lastPage: 1
  },
  getters: {
  },
  mutations: {
    UPDATE_SINGLE_LOGISTIC(state, payload) {
      return state.single_logistic_data = payload.data
    },
    RESET_SINGLE_LOGISTIC(state) {
      state.single_logistic_data = {
        srn: '',
        items: '',
        doc_desc: '',
        doc_date: '',
        doc_time: '',
        etd_desc: '',
        etd_date: '',
        etd_time: '',
        etb_desc: '',
        etb_date: '',
        etb_time: '',
        eta_desc: '',
        eta_date: '',
        eta_time: '',
        customs_desc: '',
        customs_date: '',
        customs_time: '',
        endorsement_desc: '',
        endorsement_date: '',
        endorsement_time: '',
        assessment_desc: '',
        assessment_date: '',
        assessment_time: '',
        paidDuties_desc: '',
        paidDuties_date: '',
        paidDuties_time: '',
        arrastre_desc: '',
        arrastre_date: '',
        arrastre_time: '',
        tabs_desc: '',
        tabs_date: '',
        tabs_time: '',
        gatepass_desc: '',
        gatepass_date: '',
        gatepass_time: '',
        delivery_desc: '',
        delivery_date: '',
        delivery_time: '',
        container_return_desc: 0,
        container_return_date: '',
        container_return_time: '',
        container_return_readableTime: '',
        last_edited: -1,
        remind_at: 5
      }
      delete state.single_logistic_data.id
    },
    RESET_MESSAGE(state) {
      state.message = ''
    },
    reset_errors(state) {
      return state.errors = {
        errors: {}
      }
    },
    SET_SINGLE_LOGISTIC_DATA(state, payload) {
      state.single_logistic_data = payload.data
    }
  },
  actions: {
      async getLogistics({ state }, data) {
        state.isLoading = true
        await axios.get('/api/v1/logistic', {
            params: {
                search: data.search ? data.search : '',
                sort_by: data.sort_by ? data.sort_by : 'created_at',
                sort_dir: data.sort_dir ? data.sort_dir : 'desc',
                perPage: data.perPage ? data.perPage : 10,
                page: data.currentPage ? data.currentPage : 1
            }
        }).then(response => {
          state.isLoading = false
          state.totalPages = response.data.meta.last_page
          state.currentPage = response.data.meta.current_page
          state.lastPage = response.data.meta.last_page
          state.logistic_data = response.data
        }).catch(error => {
          state.isLoading = false
            state.message = error.response.statusText
        });
      },
      async searchBySrn({ state, commit }, data) {
        state.isLoading = true
        await axios.get('/api/v1/search-srn', {
            params: {
                srn: data.search ? data.search : '',
            }
        }).then(response => {
          commit('RESET_MESSAGE')
            commit({
              type: 'UPDATE_SINGLE_LOGISTIC',
              data: response.data.data[0]
            })
            state.isLoading = false;
        }).catch(error => {
            state.message = error.response.data.message
            state.isLoading = false;
        });
      },

      async createLogistic({ state }, data) {
        state.isLoading = true
        await axios.post('/api/v1/logistic', data).then(response => {
          state.isLoading = false
          state.message = response.data
          state.errors.errors = {}
        }).catch(error => {
          state.isLoading = false
          if(error.response.status === 422) {
            state.message = error.response.data.message
            if(error.response.data.errors)
            {
              state.errors.errors = error.response.data.errors
            }
          }
          else {
            state.message = error.response.statusText;
          }
        });
      },
      async updateLogistic({ state, commit }, data) {
        state.isLoading = true
        await axios.post('/api/v1/logistic/'+data.id, data).then(response => {
          state.isLoading = false
          state.message = response.data
          commit('RESET_SINGLE_LOGISTIC')
          state.errors.errors = {}
        }).catch(error => {
          state.isLoading = false
          if(error.response.status === 422) {
            state.message = error.response.data.message;
            if(error.response.data.errors)
            {
              state.errors.errors = error.response.data.errors
            }
          }
          else {
            state.message = error.response.statusText;
          }
        });
      },
      async deleteLogistic({ state, commit }, id) {
        await axios.post('/api/v1/logistic/'+id, {
            _method: 'DELETE'
        }).then(() => {
          commit('reset_errors')
          state.message = 'Delete Successful'
        }).catch(error => {
          state.message = error.response.statusText;
        });
      },
      async poll_reminder({state}) {
        await axios.get('/api/v1/logistic-remind').then(response => {
          state.logistic_notif_data = response.data.data
          localStorage.setItem('poll_time',
            JSON.stringify({
              'poll_time': response.data.poll_time * 60000,
              'poll_time_complete': response.data.poll_time
                            ? addMinutes(new Date(), response.data.poll_time)
                            : ''
            })
          )
          state.notif_message = response.data.msg
        })
        .catch(error => {
          console.log(error)
        })
      }
  },
  modules: {
  }
})