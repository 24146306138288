import axios from 'axios'
export default ({
  namespaced: true,
  state: {
    errors: {},
    isLoading: false,
    message: '',
    event_data: {},
    single_event_data: {
    },
    totalPages: 1,
    currentPage: 1,
    lastPage: 1,
    uploaded_names: [],
    isSuccess: false,
    fail: false
  },
  getters: {
  },
  mutations: {
    UPDATE_EVENT_EVENT(state, payload) {
      return state.event_event_data = payload.data
    },
    RESET_SINGLE_EVENT(state) {
      state.single_event_data = {
      }
    },
    RESET_MESSAGE(state) {
      state.message = ''
    },
    reset_errors(state) {
      return state.errors = {}
    },
    SET_SINGLE_EVENT_DATA(state, payload) {
      state.single_event_data = payload.data
    }
  },
  actions: {
      async getEvents({ state }, data) {
        state.isLoading = true
        await axios.get('/api/v1/event', {
            params: {
                search: data.search ? data.search : '',
                sort_by: data.sort_by ? data.sort_by : 'created_at',
                sort_dir: data.sort_dir ? data.sort_dir : 'desc',
                perPage: data.perPage ? data.perPage : 10,
                page: data.currentPage ? data.currentPage : 1
            }
        }).then(response => {
          state.isLoading = false
            state.totalPages = response.data.meta.last_page
            state.currentPage = response.data.meta.current_page
            state.lastPage = response.data.meta.last_page
            state.event_data = response.data
            state.isSuccess = true
        }).catch(error => {
          state.isLoading = false
          state.message = error.response.statusText
          state.isSuccess = false
        });
      },
      async getEvent({ state }, id) {
        state.isLoading = true
        await axios.get('/api/v1/event/'+id).then(response => {
          state.isLoading = false
          state.single_event_data = response.data
          state.fail = false
          state.isSuccess = true
        }).catch(error => {
          state.isLoading = false
          state.message = error.response.statusText
          if(error.response.status === 404) state.message = 'Not Found'
          state.fail = true
          state.isSuccess = false
        });
      },
      async createEvent({ state }, data) {
        state.isLoading = true
        await axios.post('/api/v1/event', data).then(() => {
          state.isLoading = false
          state.message = 'Event Created'
          state.errors = {}
          state.isSuccess = true
        }).catch(error => {
          state.isLoading = false
          if(error.response.status === 422) {
            state.message = error.response.data.message;
            if(error.response.data.errors)
            {
              state.errors = error.response.data.errors
            }
          }
          else {
            state.message = error.response.statusText;
          }
          state.isSuccess = false
        });
      },
      async updateEvent({ state, commit }, data) {
        state.isLoading = true
        data['_method'] = 'PATCH'
        await axios.post('/api/v1/event/'+data.id, data).then(response => {
          state.isLoading = false
          state.message = response.data
          commit('RESET_SINGLE_EVENT')
          state.errors = {}
          state.message = 'Update was Successful'
          state.isSuccess = true
        }).catch(error => {
          state.isLoading = false
          if(error.response.status === 422) {
            state.message = error.response.data.message;
            if(error.response.data.errors)
            {
              state.errors = error.response.data.errors
            }
          }
          else {
            state.message = error.response.statusText;
          }
          state.isSuccess = false
        });
      },
      async deleteEvent({ state, commit }, id) {
        await axios.post('/api/v1/event/'+id, {
            _method: 'DELETE'
        }).then(() => {
          commit('reset_errors')
          state.message = 'Delete Successful'
          state.isSuccess = true
        }).catch(error => {
          state.message = error.response.statusText;
          state.isSuccess = false
        });
      },
      async upload({ state, commit }, file_data) {
        state.isLoading = true
        await axios.post('/api/v1/upload', file_data).then(response => {
        commit('reset_errors')
        state.uploaded_names = response.data
        state.isLoading = false
        }).catch(error => {
          if(error.response.status === 422) {
            state.message = error.response.data.message;
            if(error.response.data.errors)
            {
              state.errors.errors = error.response.data.errors
            }
          }
          else {
            state.message = error.response.statusText;
          }
        });
        state.isLoading = false
        state.isSuccess = false
      },
  },
  modules: {
  }
})