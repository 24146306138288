import { createStore } from 'vuex'
import axios from 'axios'
import { setCookie, getCookie, deleteCookie } from '../assets/js/create-cookie.js'
import { differenceInMinutes } from 'date-fns'

import user from './modules/tatsing-group/user'
import logistic from './modules/tatsing-group/logistic'
import event from './modules/tatsing-group/event'

import tablecontroller from './modules/tablecontroller'

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = 'Bearer '+ getCookie('token') || '';
axios.defaults.baseURL = "https://tsg-api.tatsing.com.ph"
// axios.defaults.baseURL = "http://localhost:8000"

export default createStore({
  state: {
    isLoggedIn: getCookie('token') ? true : false,
    isReauthenticating: false,
    hasPendingRequest: 0,
    isFailedReauthenticating: getCookie('isFailedReauthenticating') ? true : false,
    errors: {
      error_msg: '',
      errors: {}
    },
    user_name: localStorage.getItem('user_name') ? localStorage.getItem('user_name') :'',
    isLoading: false,
    perms: {
      viewUsers: false,
      showUser:  false,
      storeUser: false,
      spartialUser: false,
      updateUser: false,
      uselfUser: false,
      deleteUser: false,
      viewLogistics: false,
      showLogistic: false,
      storeLogistic: false,
      updateLogistic: false,
      deleteLogistic: false,
      viewEvents: false,
      showEvent: false,
      storeEvent: false,
      updateEvent: false,
      deleteEvent: false,
    },
    visitor_stats: {}
  },
  getters: {
    GET_ERRORS: function(state) {
      return state;
    },
    GET_LOGIN_STATUS : function(state) {
      return state.isLoggedIn;
    },
    GET_USER_NAME: function(state) {
      return state.user_name;
    },
    get_user_name(state, payload) {
      state.user_name = payload.name;
    },
    get_perm: () => (perm_name) => {
      if(getCookie('perms')) {
        const obj = [getCookie('perms')]
        const parsed = JSON.parse(obj)
        if(parsed[perm_name]) {
          return true
        }
        return false
      }
      return
    },
  },
  mutations: {
    update_login (state) {
      state.isLoggedIn = true;
    },
    set_user_name(state, payload) {
      state.user_name = payload.name;
    },
    set_view_user(state) {
      return state.perms.viewUsers = true
    },
    set_show_user(state) {
      return state.perms.showUser = true
    },
    set_store_user(state) {
      return state.perms.storeUser = true
    },
    set_spartial_user(state) {
      return state.perms.spartialUser = true
    },
    set_update_user(state) {
      return state.perms.updateUser = true
    },
    set_uself_user(state) {
      return state.perms.uselfUser = true
    },
    set_delete_user(state) {
      return state.perms.deleteUser = true
    },
    set_view_logistic(state) {
      return state.perms.viewLogistics = true
    },
    set_show_logistic(state) {
      return state.perms.showLogistic = true
    },
    set_store_logistic(state) {
      return state.perms.storeLogistic = true
    },
    set_update_logistic(state) {
      return state.perms.updateLogistic = true
    },
    set_delete_logistic(state) {
      return state.perms.deleteLogistic = true
    },
    set_view_event(state) {
      return state.perms.viewEvents = true
    },
    set_show_event(state) {
      return state.perms.showEvent = true
    },
    set_store_event(state) {
      return state.perms.storeEvent = true
    },
    set_update_event(state) {
      return state.perms.updateEvent = true
    },
    set_delete_event(state) {
      return state.perms.deleteEvent = true
    },
    set_allPerm_true(state) {
      state.perms = {
        viewUsers: true,
        showUser: true,
        storeUser: true,
        spartialUser: true,
        updateUser: true,
        uselfUser: true,
        deleteUser: true,
        viewLogistics: true,
        showLogistic: true,
        storeLogistic: true,
        updateLogistic: true,
        deleteLogistic: true,
        viewEvents: true,
        showEvent: true,
        storeEvent: true,
        updateEvent: true,
        deleteEvent: true,
      }
    },
    isLoading(state) {
      state.isLoading = true
    },
    isFinishedLoading(state) {
      state.isLoading = false
    },
    isReauthenticating(state) {
      state.isReauthenticating = true
    },
    isFinishedReauthenticating(state) {
      state.isReauthenticating = false
    },
    increasePendingRequest(state) {
      state.hasPendingRequest++
    },
    decreasePendingRequest(state) {
      state.hasPendingRequest--
    },
    isFailedReauthenticating() {
      setCookie('isFailedReauthenticating', true, 2);
    },
    close_expired(state) {
      deleteCookie('isFailedReauthenticating');
      state.isFailedReauthenticating = false
    },
  },
  actions: {
    async loginUser( {state, commit}, user) {
      state.isLoading = true
      await axios.get('/sanctum/csrf-cookie').then(
        await axios.post('/api/v1/login', {
            username: user.username,
            password: user.password
        }).then(login_response => {
            Promise.resolve(axios.defaults.headers.common['Authorization'] = 'Bearer '+ login_response.data.token);
            setCookie('token', login_response.data.token, login_response.data.expirationTimer);

            if(login_response.data.abilities.includes('*')) {
              commit('set_allPerm_true')
              setCookie('perms', JSON.stringify(state.perms), login_response.data.expirationTimer)
            }
            else {
              for(var i = 0; i < login_response.data.abilities.length; i++) {
                var str_split = login_response.data.abilities[i].split(':')
                var str_final = str_split[1]

                if(str_split[1].includes('-')) {
                  var str_split1 = str_split[1].split('-')
                  str_final = str_split[1][0] + str_split1[1]
                }
                commit(`set_${str_final}_${str_split[0]}`)
              }

              for(var key in state.perms) {
                if(state.perms[key] == false)
                {
                  delete state.perms[key]
                }
              }
              setCookie('perms', JSON.stringify(state.perms), login_response.data.expirationTimer)
              
            }

            if(login_response.data.profile_photo) this.dispatch('user/getPhoto', { id:  login_response.data.id, photo_name:  login_response.data.profile_photo})
            commit('update_login');
            localStorage.setItem("user_name", login_response.data.full_name);
            commit({type: 'set_user_name', name: login_response.data.full_name})

            
            const now = new Date();
            now.setTime(now.getTime() + login_response.data.expirationTimer * 60 * 1000);
            setCookie('expires', now.toUTCString(), login_response.data.expirationTimer);
            state.isLoading = false
        }).catch(error => {
            if(error.response.status === 422) {
              state.errors.error_msg = error.response.data.message;
              if(error.response.data.errors)
              {
                state.errors.errors = {
                  'username': error.response.data.errors.username,
                  'password': error.response.data.errors.password,
                }
              }
            }
            if(error.response.status === 403) {
              state.errors.error_msg = error.response.data.message;
            }
            if(error.response.status === 429) {
              state.errors.error_msg = 'Please wait 5 minutes before retrying'
            }
            state.isLoading = false
          }
      ));
    },
    async logoutUser({state}) {
      state.isLoading = true
      await axios.post('/api/v1/logout').then(() =>{
        deleteCookie('token')
        deleteCookie('perms')
        deleteCookie('expires')
        window.localStorage.clear()
        window.location.reload()
        state.isLoading = false
      }).catch(() => {
          state.isLoading = false
      });
    },
    async getVisitorStats({state}) {
      state.isLoading = true
      await axios.get('/api/v1/visitor-stats').then(response =>{
        state.visitor_stats = response.data
        state.isLoading = false
      }).catch(() => {
          state.isLoading = false
      });
    },
    async reauth({state, commit}) {
      if((differenceInMinutes(new Date(getCookie('expires')), new Date()) < 2  && !state.isReauthenticating) && (state.hasPendingRequest === 0)) {
        commit('isReauthenticating')
        axios.post('/api/v1/re-auth').then(login_response => {
          setCookie('token', login_response.data.token, login_response.data.expirationTimer);
            const now = new Date();
            now.setTime(now.getTime() + login_response.data.expirationTimer * 60 * 1000);
            setCookie('expires', now.toUTCString(), login_response.data.expirationTimer);
            axios.defaults.headers.common['Authorization'] = 'Bearer '+ login_response.data.token;

          if(login_response.data.abilities.includes('*')) {
            commit('set_allPerm_true')
            setCookie('perms', JSON.stringify(state.perms), login_response.data.expirationTimer)
          }
          else {
            for(var i = 0; i < login_response.data.abilities.length; i++) {
              var str_split = login_response.data.abilities[i].split(':')
              var str_final = str_split[1]

              if(str_split[1].includes('-')) {
                var str_split1 = str_split[1].split('-')
                str_final = str_split[1][0] + str_split1[1]
              }
              commit(`set_${str_final}_${str_split[0]}`)
            }

            for(var key in state.perms) {
              if(state.perms[key] == false)
              {
                delete state.perms[key]
              }
            }
            setCookie('perms', JSON.stringify(state.perms), login_response.data.expirationTimer)
            
          }

          commit('update_login');
          commit('isFinishedReauthenticating')
          state.isLoading = false
          }).catch(error => {
              state.errors.error_msg = error.response.statusText;
              commit('isFinishedReauthenticating')
              state.isLoading = false
            }
        );
      }
      return
    }
  },
  modules: {
    user,
    logistic,
    event,
    tablecontroller
  }
})
