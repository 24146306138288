

    // eslint-disable-next-line no-unused-vars
export function setCookie(name, value, time, path = '/', domain = 'impex.tatsing.com.ph') {
// export function setCookie(name, value, time, path = '/', domain = 'localhost') {
  const now = new Date();
  now.setTime(now.getTime() + time * 60 * 1000);
  let expires = "expires="+now.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";" + "path="+path + ";" + "domain="+domain + ";";
}

    // eslint-disable-next-line no-unused-vars
export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function deleteCookie(name) {
  setCookie(name,"",-1);
}