import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { deleteCookie } from '@/assets/js/create-cookie.js'

import './assets/vendor/aos/aos.css'
import './assets/vendor/bootstrap/css/bootstrap.min.css'
import './assets/vendor/bootstrap-icons/bootstrap-icons.css'
import './assets/vendor/boxicons/css/boxicons.min.css'

import AOS from "./assets/vendor/aos/aos.js"

AOS.init();

axios.interceptors.request.use((config) => {
  store.commit('increasePendingRequest')
  return config
}, (error) => {
  return Promise.reject(error);
})

axios.interceptors.response.use((response) => {
  store.commit('decreasePendingRequest')
  const response_data = response
  store.dispatch('reauth')
  return response_data
}, (error) => {
  store.commit('decreasePendingRequest')
  if (error.response.status == 401) {
    deleteCookie('token')
    deleteCookie('perms')
    deleteCookie('expires')
    window.localStorage.clear()
    store.commit('isFailedReauthenticating')
    location.reload()
  }
  return Promise.reject(error);
})

createApp(App).use(store).use(router).mount('#app')
