<template>
  <div>
    <Sidebar v-if="this.$store.getters.GET_LOGIN_STATUS" />
    <PreloaderVue v-show="load || $store.state.isLoading"/>
    <div v-if="!this.$store.getters.GET_LOGIN_STATUS">
      <router-view/>
    </div>
    <div v-if="this.$store.getters.GET_LOGIN_STATUS" v-show="isVisible"
      class="position-fixed overflow-hidden" style="bottom: 10%; right: 20px; max-height: 35%;">
      <div v-for="data in notif_data" :key="data"
        class="toast my-2" role="alert" aria-live="assertive" aria-atomic="true" style="z-index: 10">
        <div class="toast-header d-flex justify-content-between text-light" style="background: #6E111D;">
          <div class="d-flex gap-2">
            <i class="bi bi-alarm" style="width: 15px; height: 15px;"></i>
            <strong class="mr-auto">Reminder</strong>
          </div>
          <div class="d-flex gap-2 align-items-center">
            <button type="button" id="close-notif"
              class="ml-2 mb-1 bg-transparent border-0 rounded text-white" style="height:25px; width: 25px;"
              data-bs-dismiss="toast" aria-label="Close">
              <span aria-hidden="true" class="fw-bold">&times;</span>
            </button>
          </div>
        </div>
        <div class="toast-body bg-white text-center">
          <div>
            Reminder for SRN: <strong>{{data}}</strong> <br>
            Do you have any information to write?
          </div>

          <div class="d-flex justify-content-center gap-4 mt-2">
            <button type="button"
              class="bg-dark text-light p-2 rounded-sm border-0" style="width: 75px;"
              data-bs-dismiss="toast" aria-label="Close">
                Close
            </button>
            <button type="button" @click="edit(data)"
              class="text-light p-2 rounded-sm border-0" style="width: 75px; background: #A5966B;">
                Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  #close-notif:hover {
    background: #c52f43!important;
  }
</style>

<script>
import Sidebar from "./components/Sidebar.vue";
import { Toast } from "@/assets/vendor/bootstrap/js/bootstrap.js";
import PreloaderVue from "./components/Preloader.vue";
import { createNamespacedHelpers } from "vuex";
createNamespacedHelpers('logistic')

export default {
 components: {
   Sidebar,
   PreloaderVue
 },
 data() {
   return {
     load: false,
     notif_data: [],
     isVisible: false,
     msg: '',
     polling: null,
     poll_time: 2000
   }
 },
 async mounted() {
   document.onreadystatechange = () => {
     if(document.readyState === 'interactive') {
      this.load = true
     }
     else {
       this.load = false
     }
   }

    this.polling = setInterval(this.poll_data, this.poll_time)
 },
 methods: {
   edit(srn) {
      if(this.$route.path === '/update-status') {
        this.$router.replace({ query: { srn: srn } })
        setTimeout(() => {this.$router.go()}, 1500)
      }
      else this.$router.push({
            path: '/update-status', query: { srn: srn }
          })
      this.isVisible = false
   },
   async poll_data() {
    if(this.$store.getters.GET_LOGIN_STATUS)
    {
      clearInterval(this.polling);
      this.notif_data = []
      await this.$store.dispatch('logistic/poll_reminder').then(() => {
        this.msg = this.$store.state.logistic.notif_message.toLowerCase()
        if(this.msg.includes('success')) {
          const obj_arryd = Object.values(this.$store.state.logistic.logistic_notif_data)
          for (let i = 0; i < obj_arryd.length; i++) {
            this.notif_data.push(obj_arryd[i])
          }
        }
      })
      if(this.msg.includes('success')) {
        this.isVisible = true
        var ms = 500
        for (let j = 0; j < this.notif_data.length; j++) {
          setTimeout(function() {
          var toast = new Toast(document.getElementsByClassName('toast')[j], {
            delay: ms + 9500
          });
          
          toast.show()}, ms)

          ms += 1000
        }
      }
      if(JSON.parse(localStorage.getItem('poll_time')).poll_time !== 500) this.poll_time = JSON.parse(localStorage.getItem('poll_time')).poll_time
      this.polling = setInterval(this.poll_data, this.poll_time)
      
    }
   }
 },
 beforeUnmount () {
    clearInterval(this.polling)
},
}
</script>
